export class CheckoutDetailModel {
  checkoutDetailId?: number;
  shippingAddressId?: number;
  billingAddressId?: number;
  outboundShippingCost?: string;
  outboundShippingOption?: string;
  outboundShippingProvider?: string;
  outboundShippingProviderImage?: string;
  outboundShippingName?: string;
  outboundShippingDurationTerms?: string;
  outboundShippingEstimatedDays?: number;
  shippingAccountId?: number;
  taxExemptCertId?: string;
  applyForCredit?: boolean;
  useCreditTerms?: number;
  solupayToken?: string;
  taxAmount?: number;
  poNumber?: string;
  saveCard?: boolean;
  useSavedCard?: boolean;
  useNewCard?: boolean;
  shipComplete?: boolean;
}

export class CheckoutDetailsPayloadModel {
  checkoutDetailId?: number;
  shippingAddressId?: number;
  billingAddressId?: number;
  outboundShippingCost?: string;
  outboundShippingOption?: string;
  outboundShippingProvider?: string;
  outboundShippingProviderImage?: string;
  outboundShippingName?: string;
  outboundShippingDurationTerms?: string;
  outboundShippingEstimatedDays?: number;
  shippingAccountId?: number;
  taxExemptCertId?: string;
  applyForCredit?: boolean;
  useCreditTerms?: number;
  expiresAt?: Date;
  poNumber?: string;
  shippingWarning?: boolean;
  useNewCard?: boolean;
  shipComplete?: boolean;
}