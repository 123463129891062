<div class="container mt-5">

  <div class="row">
    <div class="col-12">
      <h3>Cart</h3>
    </div>
  </div>

  <ng-container *ngIf="!cart || !isBrowser()">
    <div class="flex-and-center pt-4 pb-4">
      <div class="d-flex text-muted pt-1">
        <div class="spinner-border mr-2" role="status"></div>
        <h4>Your cart is being loaded...</h4>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="cart && isBrowser()">
    <div class="row">
      <div class="col-md-8">
        <div class="container mb-1 pt-1 " *ngIf="cart?.items.length === 0 && rfqItems.length === 0">
          <h3>Cart is empty!</h3>
          <div class="table-responsive shopping-cart" *ngIf="removedCartItems.length > 0 || removedRfqItems.length > 0">
            <table class="table">
              <tbody>
                <tr *ngFor="let item of removedCartItems; let itemIndex = index">
                  <td>
                    <ng-container *ngTemplateOutlet="removedItem;
                    context: {
                      partNumber: item.partNumber,
                      customProductLabel: item.customProductLabel,
                      quoteItemQuantityBreakSource: item.quoteItemQuantityBreakSource,
                      itemTitle: item.itemTitle,
                      categoryUrlSlug: item.categoryUrlSlug
                    }"></ng-container>
                  </td>
                </tr>
                <tr *ngFor="let item of removedRfqItems; let itemIndex = index">
                  <td>
                    <ng-container *ngTemplateOutlet="removedItem;
                    context: {
                      partNumber: item.partNumber,
                      customProductLabel: item.customProductLabel,
                      quoteItemQuantityBreakSource: item.quoteItemQuantityBreakSource,
                      itemTitle: item.itemTitle,
                      categoryUrlSlug: item.categoryUrlSlug
                    }"></ng-container>
                  </td>
                </tr>
              </tbody>
            </table>

              <a class="pt-5 btn btn-outline-secondary" [routerLink]="previousUrl.url" [queryParams]="previousUrl.paramMap">
                <fa-icon class="text-muted mr-5" [icon]="faChevronLeft" size="sm"></fa-icon>
                Back to Shopping
              </a>
  
          </div>
        </div>
        <div class="container cart-container mb-1 pt-1 " *ngIf="cart.items.length !== 0 || rfqItems.length !== 0">
          <form [formGroup]="cartForm" class="cart-form">
            <ng-container *ngIf="failedRecalculations.length > 0">
              <div class="d-flex flex-row flex-align-center alert alert-danger p-3">
                <fa-icon class="text-danger mr-3" [icon]="faExclamationTriangle" size="1x"></fa-icon>
                <div>
                  An item in your cart is no longer available as quoted. Please go back to the product detail page and
                  review the updated quote options. You may also choose to remove the affected item(s) from your cart.
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="cartItemAdjustments.length > 0">
              <div class="d-flex flex-column alert alert-warning p-3">
                <ng-container *ngFor="let itemAdjustment of cartItemAdjustments">
                  <div class="d-flex flex-row flex-align-center mb-5"
                    *ngIf="itemAdjustment.fulfillmentDifferences && itemAdjustment.fulfillmentDifferences.totalPrice">
                    <fa-icon class="text-warning mr-3" [icon]="faExclamationTriangle" size="1x"></fa-icon>
                    <div *ngIf="itemAdjustment.fulfillmentDifferences.totalPrice">
                      {{itemAdjustment.fulfillmentDifferences.priceChangeMessage}}
                    </div>
                  </div>
                  <div class="d-flex flex-row flex-align-center mb-5"
                    *ngIf="itemAdjustment.fulfillmentDifferences && itemAdjustment.fulfillmentDifferences.leadTimeChangeMessage">
                    <fa-icon class="text-warning mr-3" [icon]="faExclamationTriangle" size="1x"></fa-icon>
                    <div *ngIf="itemAdjustment.fulfillmentDifferences.leadTime">
                      {{itemAdjustment.fulfillmentDifferences.leadTimeChangeMessage}}
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <div class="table-responsive shopping-cart" *ngIf="cart.items.length > 0 || removedCartItems.length > 0">
              <table id="customDataTable" class="table">
                <thead *ngIf="cart.items.length > 0">
                  <tr>
                    <th class="text-gray-dark">Product</th>
                    <th class="text-center text-gray-dark">Quantity</th>
                    <th class="text-center text-gray-dark">Unit Cost</th>
                    <th class="text-center text-gray-dark">Subtotal</th>
                    <th>
                      <!-- empty header for delete button col -->
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of cart.items; let itemIndex = index">
                    <ng-container *ngIf="!item.pricingExpired">
                      <tr app-cart-row-item [enableRemove]="true" [item]="item" [expired]="false"
                        [pricePerUnit]="item.pricePerUnit | currency:'USD':'symbol':'1.4-4'"
                        [totalPrice]="item.totalPrice | currency:'USD'" 
                        [deliveryDateMultiple]="computeItemShipDate(item)"
                        (removeFromCartEmitter)="displayRemoveFromCartModal($event)"></tr>
                    </ng-container>
                    <ng-container *ngIf="item.pricingExpired">
                      <ng-container *ngIf="failedRecalculationCheck(item)">
                        <tr app-cart-row-item [enableRemove]="true" [item]="item" [expired]="true" [pricePerUnit]="'--'"
                          [totalPrice]="'--'" [shippingDate]="" [failedMessage]="'Failed recalculating'"
                          (navigateBackToProductEmitter)="navigateBackToProduct($event)"
                          (removeFromCartEmitter)="displayRemoveFromCartModal($event)"></tr>
                      </ng-container>
                      <ng-container *ngIf="!failedRecalculationCheck(item)">
                        <tr app-cart-row-item [item]="item" [expired]="true" [price]="'--'" [pricePerUnit]="'--'"
                          [totalPrice]="'Recalculating'" [shippingDate]=""></tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <tr *ngFor="let item of removedCartItems; let itemIndex = index">
                    <td colspan="6">
                      <ng-container *ngTemplateOutlet="removedItem;
                        context: {
                          partNumber: item.partNumber,
                          customProductLabel: item.customProductLabel,
                          quoteItemQuantityBreakSource: item.quoteItemQuantityBreakSource,
                          itemTitle: item.itemTitle,
                          categoryUrlSlug: item.categoryUrlSlug
                        }"></ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive shopping-cart mt-4"
              *ngIf="rfqItems.length > 0 || removedRfqItems.length > 0">
              <h4 #rfqSection>Request For Quote</h4>
              <table id="rfqTable" class="table">
                <tbody formArrayName="rfqItems">
                  <ng-container *ngFor="let item of rfqItemsControlsArray; let itemIndex = index">
                    <app-cart-row-rfq-item 
                      [edit]="true" 
                      [item]="item"
                      (removeFromCartEvent)="removeRfqFromCart(item)" 
                      style="display: contents"></app-cart-row-rfq-item>
                  </ng-container>
                  <tr *ngFor="let item of removedRfqItems; let itemIndex = index">
                    <td colspan="6">
                      <ng-container *ngTemplateOutlet="removedItem;
                        context: {
                          partNumber: item.partNumber,
                          customProductLabel: item.customProductLabel,
                          quoteItemQuantityBreakSource: item.quoteItemQuantityBreakSource,
                          itemTitle: item.itemTitle,
                          categoryUrlSlug: item.categoryUrlSlug
                        }"></ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-4">
        <app-cart-summary-card [proceedDisabled]="!rfqItems.valid"></app-cart-summary-card>
      </div>
    </div>
  </ng-container>

  <ng-template let-partNumber="partNumber" let-itemTitle="itemTitle" let-categoryUrlSlug="categoryUrlSlug"
    let-quoteItemQuantityBreakSource="quoteItemQuantityBreakSource" let-customProductLabel="customProductLabel"
    #removedItem>
    <div class="product-item">
      <div class="product-info">
        <h4 class="removed-title d-flex flex-row">
          <ng-container *ngIf="quoteItemQuantityBreakSource">
            <a class="link-style mr-5" (click)="navigateToQuote(quoteItemQuantityBreakSource)">{{customProductLabel ||
              partNumber}}</a> was removed from your cart.
          </ng-container>
          <ng-container *ngIf="!quoteItemQuantityBreakSource">
            <div class="link-style mr-5"
              [routerLink]="[categoryUrlSlug ? '/product/'+categoryUrlSlug : '/product', partNumber]">{{partNumber}}
            </div>
            <div>was removed from your cart.</div>
          </ng-container>
        </h4>
      </div>
    </div>
  </ng-template>
  <ng-container>
    <app-recommendations type="add-on" context="cart" header="Accessories"></app-recommendations>
  </ng-container>

</div>

<!-- 
<pre>
  {{ cartForm.value | json }}
</pre> -->
