import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { CartService } from '../../core/services/cart.service';
import { CheckoutService } from '../../core/services/checkout.service';
import { CustomerService } from '../../core/services/customer.service';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { ShippingService } from '../../core/services/shipping.service';
import { DateService } from '../../core/services/date.service';
import { ItemDateService } from '../../core/services/item-date.service';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CheckoutDetailModel } from '../../shared/checkout-detail.model';
import { ShippingAccountModel } from '../../shared/shipping-account.model';
import { NotificationService } from '../../core/services/notification.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { AnalyticsService } from '../../analytics/analytics.service';
import { SentryService } from '../../core/services/sentry.service';
import { CheckoutStepEnum, CheckoutWizardService } from '../checkout-wizard.service';
import { CartPayloadRfqModel } from 'src/app/shared/cart-payload.model';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping-method.component.html',
  styleUrls: ['./shipping-method.component.scss']
})
export class ShippingMethodComponent implements OnInit {
  @ViewChild("checkoutFlowTop", {static: true}) checkoutFlowTop: ElementRef;

  showAddressForm: boolean = false;
  showShippingAccountForm: boolean = false;

  cart;
  addresses = [];
  selectedAddressId = null;
  outboundShippingOption = null;
  shippingAddressesLoading = false;
  shippingMethods;
  unfilteredShippingMethods = [];
  shippingCollect: boolean = false;
  shippingAccountOption = null;
  shippingAccounts: ShippingAccountModel[] = [];
  shippingAccountsLoading: boolean = false;
  shippingAccountsLoaded: boolean = false;
  retryShippo:boolean = true;
  sentAnalytics = false;
  shipCompleteFlag:boolean = false;
  estimatedShippingDays;
  cartForm: FormGroup;
  
  faInfoCircle = faInfoCircle;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  
  checkoutDetails: CheckoutDetailModel;

  shippingAccountFormats = {
    ups: 'UPS',
    fedex: 'FedEx'
  }

  editingShippingAccount: ShippingAccountModel;
  public editingShippingAccountSubject = new BehaviorSubject<ShippingAccountModel | null>(null);

  // editingAddress:FormAddressModel;
  // public editingAddressSubject = new BehaviorSubject<FormAddressModel | null>(null);

  private ngUnsubscribe = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cartService: CartService,
    private checkoutService: CheckoutService,
    private customerService: CustomerService,
    private formBuilder: FormBuilder,
    private shippingService: ShippingService,
    private sentryService: SentryService,
    private dateService: DateService,
    private itemDateService:ItemDateService,
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private checkoutWizard: CheckoutWizardService
  ) { 
    this.cartForm = this.formBuilder.group({
      rfqItems: new FormArray([])
    })
  }

  ngOnInit() {
    this.cartService.cartSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(cart => {
      this.cart = cart;
      if (cart)
        this.checkoutService.initializeCheckoutDetails(this.cart);

        if (cart && cart.rfqItems) {
          // cart subscription fires a few times (bug?); so reset 
          //  rfq items each time
          while (this.rfqItems.length !== 0) {
            this.rfqItems.removeAt(0);
          }
          cart['rfqItems'].forEach((rfqItem) => {
            this.rfqItems.push(this.createRfqItem(rfqItem))
          });
        }

        this.checkoutService.checkoutDetailsSubject
        .pipe(
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(checkoutDetails => {
          if (checkoutDetails) {
            this.checkoutDetails = checkoutDetails;
            if (!checkoutDetails.checkoutDetailId) return;
            // if their checkout details have expired, bounce them back to the cart page
            if(this.checkoutService.checkoutDetailsExpired(checkoutDetails)){
              this.checkoutService.deleteCheckoutDetails(this.cart.cartId, checkoutDetails.checkoutDetailId).pipe(
                takeUntil(this.ngUnsubscribe)
              ).subscribe(res => {
                this.notificationService.broadcastFailureNotification({
                  message:"Your checkout session has expired please review your cart details to proceed",
                  options: {
                    timeout: true
                  }
                });
                this.router.navigate(['/cart']);
              })
            } else {

              // not expired and all data loaded; UI is in steady state;
              //  Now is a good time to fire the Google Analytics Enhanced 
              //  E-Commerce 'Checkout' event to begin checkout flow analytics
              if (this.cart && this.cart.checkoutDetailId && this.cart.items && this.cart.items.length > 0 && !this.sentAnalytics) {
                const evt = {
                  'event' : 'checkout',
                  'ecommerce': {
                    'checkout': {
                      'actionField': { 'step': 2 },
                      'products': []
                    }
                  }
                };
          
                this.cart.items.forEach((item) => {
                  const itemAnalytics = {
                    'name': item.partNumber,
                    'price': item.pricePerUnit,
                    'brand': 'Marco',
                    'category': 'O-Rings',
                    'quantity': item.quantity
                  };
                  evt.ecommerce.checkout.products.push(itemAnalytics);
                });
                this.analyticsService.trackEcom(evt);
                this.sentAnalytics = true;
              }
            }
    
            if (!this.outboundShippingOption && checkoutDetails.outboundShippingOption) {
              this.outboundShippingOption = checkoutDetails.outboundShippingOption;
            }
            

            this.estimatedShippingDays = checkoutDetails.outboundShippingEstimatedDays;
    
            if (!this.shippingAccountOption && checkoutDetails.shippingAccountId) {
              this.shippingCollect = true;
              this.toggleShippingCollect();
            }

            if(checkoutDetails.shipComplete){
              this.shipCompleteFlag = true;
            } else {
              this.shipCompleteFlag = false;
            }
    
            if (checkoutDetails.shippingAddressId && checkoutDetails.shippingAddressId !== this.selectedAddressId) {
              this.selectedAddressId = checkoutDetails.shippingAddressId;
              this.getShippingOptions();
            } 
            // else if (!this.selectedAddressId && this.addresses.length) {
            //   this.selectNewAddress(addresses[0].customer_address_id);
            // }
          }
        });
      // });
      // this.checkoutService.initializeCheckoutDetails(this.cart);
    });
  }

  ngAfterViewInit() {
    if (this.checkoutFlowTop) this.checkoutFlowTop.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  computeDeliveryTime(deliveryDays) {
    return this.dateService.computeDeliveryDate(this.cart, deliveryDays);
  }

  computeItemDeliveryTime(item) {
    return this.itemDateService.getDatesForItem(item, this.estimatedShippingDays, this.cart, this.shipCompleteFlag);
  }

  getShippingOptions() {
    this.shippingAddressesLoading = true;

    if(!this.shipCompleteFlag){
      this.shippingService.getSplitShippingRatesForAddress(this.selectedAddressId,
        this.cart.cartId).subscribe((shippingMethods) => {
          this.unfilteredShippingMethods = shippingMethods;

          this.filterShippingMethodsByCarrier();
          this.shippingAddressesLoading = false;

          if (!this.shippingMethods.rates.length && this.outboundShippingOption) this.clearShippingMethod();
        });
    } else {

      let weightLbs = .5;
      const lengthIn = 5;
      const widthIn = 5;
      const heightIn = 5;
  
      this.cart.items.forEach((cartItem) => {
        if (cartItem.weight) weightLbs += parseFloat(cartItem.weight);
      });
      this.shippingService.getShippingRatesForAddress(this.selectedAddressId,
        Math.round( weightLbs ), lengthIn, widthIn, heightIn).subscribe((shippingMethods) => {
          this.unfilteredShippingMethods = shippingMethods;
  
          this.filterShippingMethodsByCarrier();
          this.shippingAddressesLoading = false;
  
          if (!this.shippingMethods.rates.length && this.outboundShippingOption) this.clearShippingMethod();
        });
    }

  }

  filterShippingMethodsByCarrier() {
    let filterShippingOptionsBy = null;
    if (this.shippingAccountOption) {
      let currentlySelectedShippingAccount = this.shippingAccounts.find(shippingAccount => {
        return shippingAccount.customerShippingAccountId === this.shippingAccountOption;
      });

      if (currentlySelectedShippingAccount) filterShippingOptionsBy = currentlySelectedShippingAccount.type;
    }

    let shippingMethods = Object.assign({}, this.unfilteredShippingMethods);
    if (shippingMethods['rates']) {
      shippingMethods['rates'] = this.unfilteredShippingMethods['rates'].filter(rate => {
        if (filterShippingOptionsBy) {
          return rate.provider.toLowerCase() === filterShippingOptionsBy;
        }
        return rate.provider === "FedEx";
      }).sort((a, b) => {
        let aFloat = parseFloat(a.amount);
        let bFloat = parseFloat(b.amount);

        if (aFloat > bFloat) return 1;
        if (aFloat < bFloat) return -1;
        else return 0;
      });

      if (shippingMethods['rates'].length === 0) {
        if (this.retryShippo) {
          setTimeout(() => {
            this.getShippingOptions();
          }, 3000);
          this.retryShippo = false;
        } else {
          this.sentryService.captureShippingError({
            message: "No shipping rates available",
            info: this.unfilteredShippingMethods['rates'],
            shippingAccountOption: this.shippingAccountOption,
            addressId: this.selectedAddressId,
            checkoutDetails: this.checkoutDetails,
            cartItems: this.cart.items
          });
        }
      }

      this.shippingMethods = shippingMethods;
  
      if (this.shippingMethods.rates.length) {
        let locateShippingOption = this.shippingMethods.rates.findIndex(method => {
          return method.servicelevel.token === this.outboundShippingOption;
        });
  
        // If the previously selected shipping option isn't available anymore, default to the first available choice
        if (locateShippingOption === -1) {
          this.selectNewShippingMethod(this.shippingMethods.rates[0].servicelevel.token, this.shippingMethods.rates[0]);
        }
      }
    }
  }

  setShipCompleteFlag(flag){
    this.clearShippingMethod();
    
    let checkoutDetailsPayload = new CheckoutDetailModel();

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;

    checkoutDetailsPayload.shipComplete = flag;
    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);

    this.getShippingOptions();
  }

  selectNewShippingAccount(newShippingAccount) { 
    let currentShippingAccountBeforeChange = this.shippingAccounts.find(shippingAccount => {
      return shippingAccount.customerShippingAccountId === this.shippingAccountOption;
    });

    this.shippingAccountOption = newShippingAccount ? newShippingAccount.customerShippingAccountId : null;

    if (currentShippingAccountBeforeChange && newShippingAccount && (newShippingAccount.type !== currentShippingAccountBeforeChange.type)) {
      // If the currently select shipping account type is different than the newly select shipping account go refetch shipping options
      this.filterShippingMethodsByCarrier();
    }

    let checkoutDetailsPayload = new CheckoutDetailModel();

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    checkoutDetailsPayload.shippingAccountId = newShippingAccount ? newShippingAccount.customerShippingAccountId : null;

    if (newShippingAccount || !this.shippingMethods) {
      checkoutDetailsPayload.outboundShippingCost = "0";
    } else {
      let currentSelectedShippingMethod = this.shippingMethods.rates.find(method => {
        return method.servicelevel.token === this.outboundShippingOption;
      });

      if (currentSelectedShippingMethod)  checkoutDetailsPayload.outboundShippingCost = currentSelectedShippingMethod.amount;
    }
    
    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }

  clearShippingMethod() {
    let checkoutDetailsPayload = new CheckoutDetailModel();
    this.outboundShippingOption = null;

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    checkoutDetailsPayload.outboundShippingOption = null;
    checkoutDetailsPayload.outboundShippingCost = null;
    checkoutDetailsPayload.outboundShippingProvider = null;
    checkoutDetailsPayload.outboundShippingProviderImage = null;
    checkoutDetailsPayload.outboundShippingName = null;
    checkoutDetailsPayload.outboundShippingDurationTerms = null;
    checkoutDetailsPayload.outboundShippingEstimatedDays = null;


    this.estimatedShippingDays = null;

    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }

  selectNewShippingMethod(newShippingMethod, shippingData) {
    this.outboundShippingOption = newShippingMethod;
    let checkoutDetailsPayload = new CheckoutDetailModel();

    checkoutDetailsPayload.checkoutDetailId = this.checkoutDetails.checkoutDetailId;
    checkoutDetailsPayload.outboundShippingOption = newShippingMethod;
    checkoutDetailsPayload.outboundShippingCost = this.shippingCollect ? "0" : shippingData.amount;
    checkoutDetailsPayload.outboundShippingProvider = shippingData.provider;
    checkoutDetailsPayload.outboundShippingProviderImage =  shippingData.provider_image_75;
    checkoutDetailsPayload.outboundShippingName = shippingData.servicelevel.name;
    checkoutDetailsPayload.outboundShippingDurationTerms = shippingData.duration_terms;
    checkoutDetailsPayload.outboundShippingEstimatedDays = shippingData.estimated_days;

    this.estimatedShippingDays = shippingData.estimated_days;

    this.analyticsService.trackEcom({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': {'step': 1, 'option': shippingData.servicelevel.name}
        }
      }
    });
    this.checkoutService.updateCheckoutDetails(checkoutDetailsPayload);
  }


  submitShippingDetails() {
    if(!this.authService.hasCustomerToken()){
      this.router.navigate(['/signin'], {queryParams:{returnUrl:'/checkout/review'}});
    } else {
      if (
        !this.checkoutDetails.shippingAddressId ||
        !this.checkoutDetails.outboundShippingCost ||
        !this.checkoutDetails.outboundShippingOption ||
        (this.shippingCollect && !this.shippingAccountOption)
      ) {
        // TODO: Throw warning
        if(!this.checkoutDetails.shippingAddressId) console.log("missing shipping address id");
        if(!this.checkoutDetails.outboundShippingCost)  console.log("missing outbound shipping cost");
        if(!this.checkoutDetails.outboundShippingOption)  console.log("missing outbound shipping option");
        if(this.shippingCollect && !this.shippingAccountOption) console.log("if ups collect is selected, there must be an account id present")
      } else {
        this.checkoutWizard.goStep(CheckoutStepEnum.REVIEW);
      }
    }
  }

  validateAllFormFields(formGroup: any) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  formatShippingCollectTypes(type) {
    return this.shippingAccountFormats[type] || type;
  }

  toggleShippingCollect() {
    if (!this.shippingCollect) {
      this.selectNewShippingAccount(null);
      this.showShippingAccountForm = false;
      this.editingShippingAccount = null;
      this.editingShippingAccountSubject.next(this.editingShippingAccount);
      this.filterShippingMethodsByCarrier();
    } else {
      if (!this.shippingAccountsLoaded) {
        this.shippingAccountsLoading = true;
        this.customerService.getShippingAccounts()
          .pipe(
            takeUntil(this.ngUnsubscribe)
          )
          .subscribe((shippingAccounts) => {
            this.shippingAccounts = shippingAccounts;
            if (!this.shippingAccountOption && this.checkoutDetails.shippingAccountId) {
              let selectedShippingAccount = shippingAccounts.find(shippingAccount => {
                return shippingAccount.customerShippingAccountId === this.checkoutDetails.shippingAccountId;	
              });
              
              if (selectedShippingAccount) {
                this.shippingAccountOption = selectedShippingAccount.customerShippingAccountId;
              } else if (shippingAccounts.length > 0) {
                this.shippingAccountOption = shippingAccounts[0].customerShippingAccountId;
                this.selectNewShippingAccount(shippingAccounts[0]);
              }
            } else if (!this.shippingAccountOption && shippingAccounts.length > 0) {
              this.shippingAccountOption = shippingAccounts[0].customerShippingAccountId;
              this.selectNewShippingAccount(shippingAccounts[0]);
              this.filterShippingMethodsByCarrier();
            }
          });
        this.shippingAccountsLoaded = true;
        this.shippingAccountsLoading = false;
      } else {
        if (!this.shippingAccountOption && this.shippingAccounts.length > 0) {
          this.shippingAccountOption = this.shippingAccounts[0].customerShippingAccountId;
          this.selectNewShippingAccount(this.shippingAccounts[0]);
          this.filterShippingMethodsByCarrier();
        } 
      }
    }
  }


  toggleShippingAccountForm(bool) {
    this.showShippingAccountForm = bool;
  }


  addNewShippingAccount() {
    this.editingShippingAccount = null;
    this.editingShippingAccountSubject.next(this.editingShippingAccount);
    this.showShippingAccountForm = true;
  }

  assignEditingShippingAccount(shippingAccount) {
    this.editingShippingAccount = shippingAccount;
    this.editingShippingAccountSubject.next(this.editingShippingAccount);
    this.toggleShippingAccountForm(true);
  }


  shippingAccountCreated(result) {
    if (result.type === 'update') {
      this.shippingAccounts = this.shippingAccounts.map(shippingAccount => {
        if (shippingAccount.customerShippingAccountId === result.shippingAccount.customerShippingAccountId) {
          return result.shippingAccount;
        } else {
          return shippingAccount;
        }
      })

      this.editingShippingAccount = null;
      this.editingShippingAccountSubject.next(this.editingShippingAccount);
    } else {
      this.shippingAccounts.push(result.shippingAccount);
      this.selectNewShippingAccount(result.shippingAccount);
    }
    this.toggleShippingAccountForm(false);
    this.filterShippingMethodsByCarrier();
  }


  createRfqItem(rfqItem: CartPayloadRfqModel): FormGroup {
    return this.formBuilder.group({
      itemTitle: [rfqItem.itemTitle, null],
      rfqItemId: [rfqItem.rfqItemId, null],
      thumbnailPath : [rfqItem.thumbnailPath, null],
      categoryUrlSlug :[rfqItem.categoryUrlSlug, null],
      quantity: [rfqItem.quantity, [Validators.required]],
      requestedLeadTime: [rfqItem.requestedLeadTime, [Validators.required]],
      reason: [rfqItem.reason, [Validators.required]],
      comment: [rfqItem.comment, null],
      productId: [rfqItem.productId, null],
      partNumber: [rfqItem.partNumber, null],
    })
  }

  get rfqItems():FormArray {
    return this.cartForm.get('rfqItems') as FormArray;    
  }

  get rfqItemsControlsArray() : FormGroup[] {
    return this.rfqItems.controls as FormGroup[]
  }



  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
