import { Injectable } from '@angular/core';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjsBusinessTime from 'dayjs-business-time';
import updateLocale from 'dayjs/plugin/updateLocale';

// add dayjs plugins
dayjs.extend(utc);
dayjs.extend(updateLocale)
dayjs.extend(dayjsBusinessTime);
dayjs.extend(timezone) 

@Injectable({ providedIn: 'root' })
export class ItemDateService {

  SHIPPING_HOUR_CUTOFF = 12;

  constructor( ) { 
    dayjs.updateLocale('us', {
      holidays: ['05-26-2025', '07-04-2025', '09-01-2025','11-27-2025', '11-28-2025', '12-25-2025'],
      holidayFormat: 'MM-DD-YYYY'
    });
  }


  // if its a quote item, always use its own delivery / shipping date
  // if we are on the cart page, we always show shipping date at an idividual item level (with potentially multiple item fulfillments)
  // if we are in the checkout process we have to obey the shipComplete flag; 0 = ship ready, 1 ship complete (wait for all items)


    // Get delivery or shipping dates for a cart or order item
    /*
        item potentially with itemDetails (itemDetails contains the multiple fulfillment components if there are any)
        deliveryFlag  1 for delivery, 0 for shipping only
        cart
        deliveryDays integer number of days it takes to deliver the item
        shipComplete 0 for ship ready 1 for ship everything together

        return a date string, or multiple fulfillment dates
    */
    public getDatesForItem(item, deliveryDays?, cart?,  shipComplete?){
        let itemLeadTime;

        let computedDate = {
            dateString:null,
            dateArray:null,
            shipComplete: shipComplete
        }

        let leadString = deliveryDays ? `Expected to arrive ` : `Expected to ship `;
        // we have a quote item, ignore other item lead times
        if(item.quoteItemQuantityBreakSource){
            itemLeadTime = this.computeLongestLeadTimeFromItem(item);
            computedDate.dateString = leadString + this.computeStartEndTimeRange(itemLeadTime);
            
            // TODO, shipping a quote item NOT delivering it?

        // shipComplete - NOT default any more, if the user wants us to hold everything and ship at the same time    
        } else if (shipComplete && cart) {
            itemLeadTime = this.computeLongestLeadTimeFromCart(cart);
            computedDate.dateString = leadString + this.buildDateString(itemLeadTime, deliveryDays);
        
        // !shipComplete - Default behavior, ship each item as soon as it can
        } else {

            // multiple fulfillment components
            if(item.itemDetails && item.itemDetails.length > 1){
                computedDate.dateArray = [];
                item.itemDetails.forEach(itemDetail => {
                    itemLeadTime = {
                        vendorCode: itemDetail.vendorCode || '',
                        leadTimeDays: itemDetail.leadTimeDays || 0,
                        leadTimeEndDays: itemDetail.leadTimeEndDays || 0
                    }
                    computedDate.dateArray.push({
                      quantity:itemDetail.quantity,
                      dateString: leadString + this.buildDateString(itemLeadTime, deliveryDays)
                    });
                  });

            // standard fulfillment but shipping individually
            } else {
                
                itemLeadTime = this.computeLongestLeadTimeFromItem(item);
                computedDate.dateString = leadString + this.buildDateString(itemLeadTime, deliveryDays);
            }
        }

        return computedDate;
    }


    public getDatesForOrderItem(item, shipComplete?){

        let computedDate = {
            dateString:null,
            dateArray:null,
            shipComplete: shipComplete
        }

        if(shipComplete){
            computedDate.dateString = `Expected to arrive ` + this.dateStringFromFixedDates(item.deliveryRange.start, item.deliveryRange.end);
        } else {
            if(item.shipCompleteDeliveryRange && item.shipCompleteDeliveryRange.length > 1){
                computedDate.dateArray = [];
                 item.shipCompleteDeliveryRange.forEach((range)=>{
                    computedDate.dateArray.push({
                      quantity:range.quantity,
                      dateString: `Expected to arrive ` + this.dateStringFromFixedDates(range.start, range.end)
                    });
                });
              } else {
                computedDate.dateString = `Expected to arrive ` + this.dateStringFromFixedDates(item.deliveryRange.start, item.deliveryRange.end);
              }
        }
        return computedDate;
    }

  private computeLongestLeadTimeFromCart(cart) {
    let leadTime = {
        vendorCode: null,
        leadTimeDays: 0,
        leadTimeEndDays: null
    }

    cart.items.forEach(cartItem => {
        //we only care about non-quote items
        if(!cartItem.quoteItemQuantityBreakSource){
            let cartItemleadTime = this.computeLongestLeadTimeFromItem(cartItem);
            if(cartItemleadTime['leadTimeDays'] >= leadTime.leadTimeDays) {
                leadTime = cartItemleadTime;
            }
        }
    })

    return leadTime;
  }

  private computeLongestLeadTimeFromItem(cartItem) {

    let leadTime = {
        vendorCode: null,
        leadTimeDays: 0,
        leadTimeEndDays: null
    };

    cartItem.itemDetails.forEach(cartItemDetails => {
      if (cartItemDetails['leadTimeDays'] >= leadTime.leadTimeDays) {
        leadTime = {
          vendorCode: cartItemDetails['vendorCode'],
          leadTimeDays: cartItemDetails['leadTimeDays'],
          leadTimeEndDays: cartItemDetails['leadTimeEndDays']
        }
      }
    });

    return leadTime;
  }


  private computeStartEndTimeRange(itemLeadTime){
    dayjs.tz.setDefault("America/New_York")
    let startTime = dayjs();

    if(startTime.hour() >= this.SHIPPING_HOUR_CUTOFF || !dayjs(startTime).isBusinessDay()){
        itemLeadTime.leadTimeDays += 1;
      if(itemLeadTime.leadTimeEndDays){
        itemLeadTime.leadTimeEndDays += 1;
      }
    }

    let leadTimeMessage = '';
    leadTimeMessage +=  startTime.addBusinessDays(itemLeadTime.leadTimeDays).format('ddd MMM DD');
    if(itemLeadTime.leadTimeEndDays){
      leadTimeMessage += ' - '
      leadTimeMessage +=  startTime.addBusinessDays(itemLeadTime.leadTimeEndDays).format('ddd MMM DD');
    }
    return leadTimeMessage;
  }

  private computeLeadTimeRange(leadTime, additionalDays) {

    dayjs.tz.setDefault("America/New_York")
    let startTime = dayjs();

    if(startTime.hour() >= this.SHIPPING_HOUR_CUTOFF || !dayjs(startTime).isBusinessDay()){
      leadTime += 1;
    }

    let leadTimeMessage = '';
    leadTimeMessage +=  startTime.addBusinessDays(leadTime).format('ddd MMM DD');
    leadTimeMessage += ' - '
    leadTimeMessage +=  startTime.addBusinessDays(leadTime + additionalDays).format('ddd MMM DD');
    return leadTimeMessage;
  }


  public buildDateString(longestLeadTime, deliveryDays?) {
    let leadTimeMessage = '';
    const deliveryDaysToAdd = deliveryDays || 0;

    if ((longestLeadTime.vendorCode === "MARCO" || !longestLeadTime.vendorCode)  && !deliveryDays) {
      if (longestLeadTime.leadTimeDays > 0){
        leadTimeMessage = this.computeLeadTimeRange(longestLeadTime.leadTimeDays, 1);
      } else {
        dayjs.tz.setDefault("America/New_York")
        let startTime = dayjs();
        if(!dayjs(startTime).isBusinessDay()){
          leadTimeMessage = startTime.addBusinessDays(1).format('ddd MMM DD');
        } else if (startTime.hour() >= this.SHIPPING_HOUR_CUTOFF){
          leadTimeMessage = 'Tomorrow';
        } else {
          leadTimeMessage = 'Today or Tomorrow';
        }
      }
    } else if (longestLeadTime.vendorCode === "MARCO" && deliveryDays) {
      leadTimeMessage = this.computeLeadTimeRange(longestLeadTime.leadTimeDays + deliveryDaysToAdd, 1);
    } else if (longestLeadTime.vendorCode === "OUTSIDE_VENDOR" || longestLeadTime.vendorCode === "ESCORT") {
      leadTimeMessage = this.computeLeadTimeRange(longestLeadTime.leadTimeDays + deliveryDaysToAdd, 4);
    } else {
        leadTimeMessage = this.computeLeadTimeRange(longestLeadTime.leadTimeDays + deliveryDaysToAdd, 4);
    }

    return leadTimeMessage;
  }


  public dateStringFromFixedDates(start, end?) {
    let leadTimeMessage = '';

    leadTimeMessage += dayjs(start).format('ddd MMM DD');

    if(end){
        leadTimeMessage += ` - ` + dayjs(end).format('ddd MMM DD');
    }

    return leadTimeMessage;
  }


  public convertTimestampToDate(timestamp) {
    return dayjs(timestamp).format('MM/DD/YYYY');
  }


}
