import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CartRowItemComponent } from './cart-row-item.component';

@NgModule({
  declarations: [CartRowItemComponent],
  imports: [
    RouterModule,
    FontAwesomeModule,
    TooltipModule.forRoot(),
    CommonModule
  ],
  exports: [
    CartRowItemComponent,
  ]
})
export class CartRowItemModule { }
