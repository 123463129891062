<div #checkoutFlowTop class="container pb-3 mb-1 pt-1"
  *ngIf="cart && (cart.items.length !== 0 || cart.rfqItems.length !== 0)">

  <hr>
  <div class="d-flex" class="pt-3" id="paymentDetailsForm" [formGroup]="paymentDetailsForm">
    <h5>Payment</h5>
    <!-- radio row if terms are available -->
    <div *ngIf="companyTerms.hasTerms" class="row">
      <div class="col-1">
        <input type='radio' value="terms" formControlName="paymentMethod" (change)="changePaymentMethod($event)"/>
      </div>
      <div *ngIf="!companyTerms.termsDescription" class="col-11">
        Purchase using business credit terms (existing Marco customers only)
        <p class="text-muted">Credit card not required if business credit account is in good standing</p>
      </div>
      <div *ngIf="companyTerms.termsDescription" class="col-11">
        Use this option to purchase with the {{companyTerms.termsDescription}} credit terms on your account.
        <p class="text-muted">Credit card not required if business credit account is in good standing</p>
      </div>
    </div>
  

    <!-- radio row if saved card is available -->
    <div *ngIf="hasCardOnFile" class="row">
      <div class="col-1">
        <input type='radio' value="savedCard" formControlName="paymentMethod" (change)="changePaymentMethod($event)"/>
      </div>
      <div class="col-8">
        Use {{ this.customer.last_card_type }} card on file ending with {{ customer.last_card_lastfour }}
      </div>
      <div class="col-3 active-text" (click)="forgetMyCard()" >Forget This Card</div>
    </div>

    <div class="row mt-3">
      <div *ngIf="companyTerms.hasTerms || hasCardOnFile" class="col-1" >
        <input type='radio' value='newCard' formControlName="paymentMethod" (change)="changePaymentMethod($event)" />
      </div>
      <div class="col-11">
        <p>Use a new card <img class="credit-card-image d-inline-block align-middle"
            src="assets/img/credit-cards.png" alt="Credit Cards"></p>
      </div>
    </div>

    <div class="row">
      <div [ngClass]="companyTerms.hasTerms || hasCardOnFile ? 'col-11 offset-1' : 'col-12'" [formGroup]="cardForm">
        <div id="solupay-wrapper" *ngIf="useNewCard()">
          <div class="d-flex flex-column">
            <!-- Name on Card -->
            <div class="form-group col-12 pl-0">
              <label for="fullname">Name on Card</label>
              <input id="fullname" class="form-control" type="text" name="cardHolderName" placeholder="Full Name"
                formControlName="cardHolderName" required>
            </div>

            <!-- SOLUPAY embed -->
            <app-solupay (validationChange)=solupayValid($event) (validationPending)="setSolupayPending($event)"
              (completePayment)=solupayTokenReceived($event) [getToken]=submitSolupayForm></app-solupay>
            <p class="errorMessage" *ngIf="paymentErrorMessage">{{paymentErrorMessage}}</p>

            <!-- DEBUGGING  -->
            <!-- <pre>4111 1111 1111 1111 </pre>
            <pre>solupayReady: {{ solupayReady }}</pre>
            <pre>cardForm.valid: {{ cardForm.valid }}</pre>
            <pre>solupayValidationPending: {{ solupayValidationPending }}</pre>
            <pre>useCreditTerms: {{ useCreditTerms() }}</pre>
            <pre>submitSolupayForm: {{ submitSolupayForm }}</pre>
            <pre>{{ (checkoutService.checkoutCardSubject | async) | json }}</pre>  -->

            <!-- Save CC -->
            <div [formGroup]="cardForm" class="form-group form-check col-12" >                  
              <input id="customerSaveCard" class="form-check-input" type="checkbox" name="saveCard"
                formControlName="saveCard" (change)="setSaveCard()">
              <label class="form-check-label" for="customerSaveCard">Save this card for future transactions</label>
              &nbsp;<fa-icon [icon]="faInfoCircle" size="sm" tooltip="This card will replace any previous cards on file."></fa-icon>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

  <hr>

  <!-- BILLING ADDRESS BEGIN -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <div>
        <div class="d-flex flex-column mt-3">
          <h5>Billing Address</h5>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="billing-shipping-same" [(ngModel)]="billingSameAsShipping"
              (ngModelChange)='setBillingToShippingAddress()'>
            <label class="form-check-label" for="billing-shipping-same">Same as shipping address.</label>
          </div>
        </div>
        <div class="d-flex mt-4" *ngIf="!billingSameAsShipping">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead class="thead-default">
                <tr>
                  <th></th>
                  <th colspan="3">Saved Addreses</th>
                </tr>
              </thead>
              <tbody>
                <tr class="pointer" *ngFor="let address of addresses">
                  <td class="align-middle">
                    <input name='shippingAddress' type='radio' [id]='address.customer_address_id'
                      [value]='address.customer_address_id' [ngModel]='selectedAddressId'
                      (ngModelChange)='selectNewAddress(address.customer_address_id)' />
                    <label [for]='address.customer_address_id'></label>
                  </td>
                  <td (click)="selectNewAddress(address.customer_address_id)" class="align-middle"><span
                      class="text-gray-dark">{{ address.first_name }} {{ address.last_name }} {{ address.address1 }}
                      {{address.city}}, {{ address.state}} {{ address.zipCode }}</span></td>
                  <td><span *ngIf="address.nickname" class="text-muted pl-15">{{ address.nickname }}</span></td>
                  <td class="link-style" (click)="assignEditingAddress(address)">Edit</td>
                </tr>
              </tbody>
            </table>
            <div>
              <span *ngIf="addresses.length === 0">
                You have no saved billing addresses.
              </span>
              <span class="link-style" (click)="addNewAddress()">
                Enter a new billing address
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex pb-1" class="mt-5" *ngIf="showAddressForm">
          <app-new-address [address]="editingAddressSubject" [addressType]="'billing'"
            (addressCreated)="addressCreated($event)" (formVisibility)="toggleAddressForm($event)"></app-new-address>
        </div>
      </div>
    </div>
  </div>
  <!-- BILLING ADDRESS END -->

  <!-- <button (click)="submitOrder()" [disabled]="btnSpinny" class="btn btn-block btn-primary mb-5 d-block d-sm-none"><fa-icon *ngIf="btnSpinny" [spin]="true" [icon]="faCircleNotch" class="mr-2"></fa-icon>Place Order</button>
  <button (click)="submitOrder()" [disabled]="btnSpinny" class="btn btn-block btn-primary d-none d-md-block"><fa-icon *ngIf="btnSpinny" [spin]="true" [icon]="faCircleNotch" class="mr-2"></fa-icon>Place Order</button> -->

  <hr>
  <!-- SHIPPING ADDRESS START -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <div class="d-flex flex-column mt-3">
        <h5>Shipping Address</h5>

        {{ shippingAddress?.first_name }} {{ shippingAddress?.last_name }} | {{ shippingAddress?.phone_number }}<br>
        {{ shippingAddress?.address1 }} {{shippingAddress?.city}}, {{ shippingAddress?.state}} {{
        shippingAddress?.zipCode }}
      </div>
      <div>
        <a class="link-style" (click)="wizard.goStep(CheckoutStepEnum.CHOOSE_ADDRESS)">Edit</a>
      </div>
    </div>
  </div>
  <!-- SHIPPING ADDRESS END -->

  <hr>

  <!-- SHIPPING METHOD START -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <div>
        <h5>Shipping Method</h5>
        {{ shippingMethod?.outboundShippingProvider }} {{ shippingMethod?.outboundShippingName }}
      </div>
      <div>
        <a class="link-style" (click)="wizard.goStep(CheckoutStepEnum.SHIPPING_METHOD)">Edit</a>
      </div>
    </div>
  </div>
  <!-- SHIPPING METHOD END -->

  <hr>

  <!-- PURCHASE ORDER START -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <div>
        <div class="d-flex flex-column mt-3" id="poForm" [formGroup]="poForm">
          <h5>PO / REFERENCE NUMBER (OPTIONAL)</h5>
          <label for="ponum">PO/Reference Number</label>
          <input id="ponum" class="form-control" type="text"
          placeholder="01234" (change)="savePoNum()" formControlName="customerPurchOrdNum">
        </div>
      </div>
    </div>
  </div>
  <!-- PURCHASE ORDER END -->


  <hr>


  <!-- TAX EXEMPT CERT START -->
  <div class="row">
    <div class="col-12 d-flex justify-content-between">
      <div class="d-flex flex-column" class="pt-3">
        <h5>Tax Exempt Certificate (Optional)</h5>
        <div class="table-responsive">
          <table class="table table-hover" *ngIf="taxExemptCertificates.length !== 0">
            <thead class="thead-default">
              <tr>
                <th colspan="4">Saved Certificates</th>
              </tr>
            </thead>
            <tbody>
              <tr class="pointer" *ngFor="let taxCert of taxExemptCertificates">
                <td class="align-middle" [ngClass]="taxCert.id === selectedTaxCertId ? 'selected-blue' : ''">
                  <input name='taxExemptCertificate' type='checkbox' [id]='taxCert.id' [value]='taxCert.id'
                    [ngModel]='selectedTaxCertId === taxCert.id' (ngModelChange)='selectNewTaxExemptCert(taxCert.id, $event)' />
                  <label [for]='taxCert.id'></label>
                </td>
                <td (click)="selectNewTaxExemptCert(taxCert.id, $event)" class="align-middle" [ngClass]="taxCert.id === selectedTaxCertId ? 'selected-blue' : ''">
                  <span class="text-gray-dark">
                    {{ taxCert.certificateNumber }}
                  </span>
                </td>
                <td (click)="selectNewTaxExemptCert(taxCert.id, $event)" [ngClass]="taxCert.id === selectedTaxCertId ? 'selected-blue' : ''">
                  <span class="text-muted">{{ taxCert.fileName }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex flex-column mt-3 mb-5 pl-2">
            <span *ngIf="taxExemptCertificates.length === 0">
              You have no saved tax exempt certificates.
            </span>
            <span class="link-style pointer" (click)="addNewTaxExemptCert()">
              Enter a new Tax Exempt Certificate
            </span>
          </div>
        </div>

        <div class="d-flex pb-1" *ngIf="showTaxExemptCertFrom">
          <app-tax-exempt-certificate class="w-100" (taxExemptCertCreated)="taxExemptCertCreated($event)"
            (formVisibility)="toggleTaxExemptForm($event)"></app-tax-exempt-certificate>
        </div>

      </div> 
    </div>
  </div>
  <!-- TAX EXEMPT CERT END -->


  <!-- an additional 'Place Order' button is presented on mobile above the order list+summary which is long -->
  <!-- This allows a mobile user to proceed quickly without a bunch of scrolling -->
  <button (click)="submitOrder()" class="btn btn-block btn-primary mt-5 d-block d-sm-none">Place Order</button>
  
  <div class="review-wrapper">
    <div class="table-responsive" *ngIf="cart.items.length > 0">
      <div class="d-flex flex-column mt-5">
        <h5>Your Order</h5>
        <div class="table-responsive">
          <table id="ordertable" class="table">
            <thead class="thead-default">
              <tr>
                <th>Product Name</th>
                <th class="text-center">Quantity</th>
                <th class="text-center">Unit Cost</th>
                <th class="text-center">Total</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of cart.items; let itemIndex = index">
                <tr app-cart-row-item [item]="item" [disableNavigate]="true" [price]="item.price | currency:'USD'"
                  [pricePerUnit]="item.pricePerUnit | currency:'USD':'symbol':'1.4-4'"
                  [totalPrice]="item.totalPrice | currency:'USD'" [deliveryDateMultiple]="computeItemDeliveryTime(item)"></tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="pt-1" *ngIf="cart.rfqItems.length > 0" [formGroup]="cartForm">
          <h5>Items for Quote</h5>
          <table id="ordertable" class="table">
            <tbody formArrayName="rfqItems">
              <ng-container *ngFor="let item of rfqItemsControlsArray; let itemIndex = index" >
                <app-cart-row-rfq-item [item]="item" style="display: contents"></app-cart-row-rfq-item>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<p class="errorMessage" *ngIf="paymentErrorMessage">{{paymentErrorMessage}}</p>
<!-- two versions of the same button; shown at different breakpoints -->
<button (click)="submitOrder()" [disabled]="btnSpinny" class="btn btn-block btn-primary mb-5 d-block d-sm-none"><fa-icon *ngIf="btnSpinny" [spin]="true" [icon]="faCircleNotch" class="mr-2"></fa-icon>Place Order</button>
<button (click)="submitOrder()" [disabled]="btnSpinny" class="btn btn-block btn-primary d-none d-md-block"><fa-icon *ngIf="btnSpinny" [spin]="true" [icon]="faCircleNotch" class="mr-2"></fa-icon>Place Order</button>


<ng-template #ccDeleteTemplate>
  <div class="modal-body">
    <div class="d-flex flex-row justify-content-between flex-align-center mb-4">
      <h4 class="mb-0">FORGET PAYMENT CARD</h4>
      <fa-icon [icon]="faTimes" (click)="declineForgetCard()" size="lg"></fa-icon>
    </div>
    <div class="mb-4">
      Are you sure you want to delete your saved card?
    </div>
    <div class="d-flex flex-row justify-content-between">
      <button type="button" class="btn btn-primary mr-2" (click)="confirmForgetCard()" >Yes</button>
      <button type="button" class="btn btn-light gray-border" (click)="declineForgetCard()" >No</button>
    </div>
  </div>
</ng-template>