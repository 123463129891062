/**
 * This injectable service is used by each step of 
 * the checkout process to manage the overall state 
 * of the checkout process.  It is the keeper of the 
 * cart object, addresses and shipping selections and
 * the current step.
 */

import { Injectable } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService, CartService, CheckoutService, CustomerService, LocationService } from '../core/services';
import { FormAddressModel } from '../shared/address.model';
import { CartPayloadModel } from '../shared/cart-payload.model';
import { RfqModel } from '../shared/rfq.model';
import { LightUserModel, UserModel } from '../shared/user.model';

export enum CheckoutStepEnum {
  CART,
  CHOOSE_ADDRESS,
  SHIPPING_METHOD,
  BILLING,
  REVIEW,
  RFQ,
  SIGNIN
}

export interface CheckoutStep {
  step: CheckoutStepEnum,
  stepTitle: string,
  stepUrl: string,
  showBreadcrumbs: boolean
}

@Injectable({
  providedIn: 'root'
})
export class CheckoutWizardService {

  public loggedInUserSubject: BehaviorSubject<LightUserModel | null>;
  public editingAddressSubject: BehaviorSubject<FormAddressModel | null> = new BehaviorSubject(null);
  public orderSubmit = new Subject;
  public orderPending = new Subject;

  public cartSubject: BehaviorSubject<CartPayloadModel | null>;
  isUserLoggedIn = false;
  private ngUnsubscribe = new Subject<void>();
  private customer;

  constructor(
    public checkoutService: CheckoutService,
    public cartService: CartService,
    public locationService: LocationService,
    public customerService: CustomerService,
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public router:Router
  ) { 
    this.showShippingRestrictionMessage = !this.locationService.isDomestic();
    this.showShippingRestrictionMessage = true;
    this.cartSubject = this.cartService.cartSubject;

    this.orderSubmit.subscribe((val)=>{
      this.orderPending.next(val);
    })
  

    this.authService.isLoggedIn().pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(loggedIn => {
      this.isUserLoggedIn = loggedIn;
    })

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          // this.currentRoute = event.url;          
          // console.log(event);
          this.setStepByUrl(event.url);
      }
    });

    this.customerService.userSubject.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe((customer:UserModel) => {
      if (customer) {
        this.customer = customer;
      }
    });


  }

  showShippingRestrictionMessage: boolean = false;
  public currentStep:CheckoutStep;

  wizardSteps:CheckoutStep[] = [
    {
      step: CheckoutStepEnum.CART,
      stepTitle: "Cart",
      stepUrl: "/cart",
      showBreadcrumbs: false
    },
    {
      step: CheckoutStepEnum.CHOOSE_ADDRESS,
      stepTitle: "Checkout: Shipping Address",
      stepUrl: "/checkout/shipping-address",
      showBreadcrumbs: true
    },
    {
      step: CheckoutStepEnum.SHIPPING_METHOD,
      stepTitle: "Checkout: Shipping Method",
      stepUrl: "/checkout/shipping-method",
      showBreadcrumbs: true
    },
    {
      step: CheckoutStepEnum.REVIEW,
      stepTitle: "Checkout: Payment & Review",
      stepUrl: "/checkout/review",
      showBreadcrumbs: true
    },
    {
      step: CheckoutStepEnum.SIGNIN,
      stepTitle: "Checkout: Signin",
      stepUrl: "/checkout/signin",
      showBreadcrumbs: false
    },

  ];

  // set the current step 
  setStepByUrl(url:string) {
    this.currentStep = this.wizardSteps.find(step => step.stepUrl === url);
  }

  goStep(newStep:CheckoutStepEnum) {
    this.orderSubmit.next(false);
    this.currentStep = this.wizardSteps.find(step => step.step === newStep);
    this.router.navigate([this.currentStep.stepUrl]);
  }

  startCheckoutFlow() {
    if (this.isUserLoggedIn) {
      if (this.cartSubject.value.getCartState() === 'rfqsOnly') {
        // if we're logged in and only have rfqs, submit them and then goto thank-you page
        // this.router.navigate(['/checkout/submit-rfq']);

        const rfq: RfqModel = {
          cartId: this.cartSubject.value.cartId,
          contactInformation: {
            firstName: this.customer.first_name,
            lastName: this.customer.last_name,
            email: this.customer.email,
            phone: this.customer.phone_number,
            company: this.customer.company_name
          },
          rfqItems: [],
        };
    
        this.cartService.convertToRfq(rfq).subscribe((rfqId) => {
          this.cartService.clearCart();
          this.router.navigate(['/checkout/complete'], { queryParams: { rfqId: rfqId } });
        }, (err) => {
          console.log(err);
        });

      } else {
        // we have at least one buyable item and we're logged in, so lets proceed to checkout
        if(this.cartSubject.value.checkoutDetailId){
          this.checkoutService.deleteCheckoutDetails(this.cartSubject.value.cartId, this.cartSubject.value.checkoutDetailId).subscribe((res) => {
            this.router.navigate(['/checkout/shipping-address']);
          },
          error => {
            // This scenario will almost always be because your token has expired. In this case, forward the user to the signin screen to continue
            this.router.navigate(['/signin'], { queryParams: { returnTo: '/checkout/shipping-address'}});
          })
        } else {
          this.router.navigate(['/checkout/shipping-address']);
        }
      }
    } else {
      if (this.cartSubject.value.getCartState() === 'rfqsOnly') {
        // we're not logged in and are just doing an rfq; lets login/create acct and then submit 
        this.router.navigate(['/signin'], { queryParams: { returnTo: '/checkout/submit-rfq'}});
      } else {
        // we're not logged in and we have one buyable item, so lets login and then redir to start checkout
        this.router.navigate(['/signin'], { queryParams: { returnTo: '/checkout/shipping-address'}});
      }
    }
  }

  public isCartEmpty() {
    const cart = this.cartSubject.value;
    return !cart || (cart.items.length === 0 && cart.rfqItems.length === 0);
  }


  public setSubmitOrder(value) {
    this.orderSubmit.next(value);
  }

}
