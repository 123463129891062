import { Component, OnInit, Input, ElementRef, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { ProductService } from '../core/services/product.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { CartService } from '../core/services/cart.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-recommendation-item-accessory',
  templateUrl: './recommendation-item-accessory.component.html',
  styleUrls: ['./recommendation-item-accessory.component.css']
})
export class RecommendationItemAccessoryComponent implements OnInit {
  @Input() product;
  @Input() listIdx;
  @ViewChild('quantityInput', {static: false}) quantityInput: ElementRef;

  fulfillmentOption:any;
  faShippingFast = faShippingFast;
  price = null;
  quantity = 1;
  loading = true;
  productRoute: string[]

  constructor(
    private cartService: CartService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private productService: ProductService
  ) { }


  // in case user clicks 
  logClickAndNavigate() {
    const evt = {
      'ecommerce': {
        'event': 'productClick',
        'click': {
          'actionField': { 'list': 'Recommendations' },
          // this will always ever be a single item array as you can
          //  only click one one object at a time with our UI
          'products': [{
            'name': this.product.partNumber,
            'price': this.product.pricePerUnit,
            'brand': 'Marco',
            'category': 'O-Rings',
            'list': 'Recommendations',
            'position': this.listIdx
          }]
        }
      }
    };
    this.analyticsService.trackEcom(evt);
    this.router.navigate(this.productRoute);
  }


  getPrice(quantity) {
    this.loading = true;

    this.productService.getQuantityPricing(this.product['_source'].part_number, quantity).subscribe(
      qtyPricing => {
        if(qtyPricing && qtyPricing.basePrice){
          this.fulfillmentOption = qtyPricing.basePrice;
          if(qtyPricing.basePrice.pricePerUnit){
            this.price = qtyPricing.basePrice.pricePerUnit;
          }
        } else {
          this.price = null
        }

        this.loading = false;
      });
  }

  addPartToCart() {
    // this.product._source = {
    //   weight_lbs:this.product.weight_lbs,
    //   product_id:this.product.product_id,
    //   part_number:this.product.part_number
    // };
    

    let itemDetails = [];

    if(this.fulfillmentOption.fulfillmentComponents){
      this.fulfillmentOption.fulfillmentComponents.forEach((fulfillmentComponent)=>{
        itemDetails.push({
          quantity: fulfillmentComponent.quantity,
          leadTimeDays: fulfillmentComponent.leadTimeDays,
          vendorCode: fulfillmentComponent.vendorCode,
          srcList: 'Recommendations'
        })
      });
    }

    this.cartService.addOptionToCart(
      {
        product: this.product,
        quantity: this.fulfillmentOption.quantity,
        productSubtotal: this.fulfillmentOption.productSubtotal,
        pricePerUnit: this.fulfillmentOption.pricePerUnit,
        totalPrice: this.fulfillmentOption.productTotal,
        logisticsFee: this.fulfillmentOption.logisticsFee,
        itemDetails: itemDetails
      }
    );

    const evt = {
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'USD',
        'add': {                                       // 'add' actionFieldObject measures.
          'actionField': {'list': 'Recommendations'},  // MISSING FROM THE DOCUMENATION (https://developers.google.com/tag-manager/enhanced-ecommerce#add)                      
          'products': [{                               //  adding a product to a shopping cart.
            'name': this.product.part_number,
            'price': this.fulfillmentOption.pricePerUnit,
            'brand': 'Marco',
            'category': 'Accessory',
            'list': 'Recommendations',
            'quantity': this.fulfillmentOption.quantity
          }]
        }
      }
    };
    this.analyticsService.trackEcom(evt);
    this.router.navigate(['/cart']);
  }

  ngOnInit(): void {
    this.getPrice(1);

    this.productRoute = ['/product/accessory', this.product["_source"].part_number];
  }


  ngAfterViewInit() {
    fromEvent(this.quantityInput.nativeElement, 'change')
      .pipe(
        debounceTime(500)
      )
      .subscribe(event => {
        let newQuantity = parseInt(event['target'].value);
        if (!isNaN(newQuantity)) {
          this.getPrice(newQuantity)
        } else {
          this.getPrice(1)
        }
      });

    fromEvent(this.quantityInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(500)
      )
      .subscribe(event => {
        let newQuantity = parseInt(event['target'].value);
        if (!isNaN(newQuantity)) {
          this.getPrice(newQuantity)
        } else {
          this.getPrice(1)
        }
      });
    }
}
