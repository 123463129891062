<div #checkoutFlowTop class="container-md no-gutter pb-3 mb-1 pt-1" *ngIf="cart && (cart.items.length !== 0 || cart.rfqItems.length !== 0)">
  <div class="d-flex flex-column">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column pb-1" *ngIf="!shippingAddressesLoading">
        <div class="flex-row">
          <div>
            <h3>Choose your delivery Option</h3>
            <div class="delivery-option">
              <label>
                <input
                  type="radio"
                  name="deliveryOption"
                  [(ngModel)]="shipCompleteFlag"
                  (ngModelChange)='setShipCompleteFlag(shipCompleteFlag)'
                  [value]="false"
                />
                Ship items ASAP 
                <small>(multiple shipments – additional shipping charges may apply)</small>
              </label>
            </div>

            <div class="delivery-option">
              <label>
                <input
                  type="radio"
                  name="deliveryOption"
                  [(ngModel)]="shipCompleteFlag"
                  (ngModelChange)='setShipCompleteFlag(shipCompleteFlag)'
                  [value]="true"
                />
                Hold my order until all items are available 
                <small>(single shipment)</small>
              </label>
            </div>
          </div>
        </div>
        <div class="flex-row">
          <div class="custom-control custom-checkbox d-flex flex-align-center mr-10 mb-3">
            <input class="custom-control-input active" type="checkbox" btnCheckbox id="shippingCollect" [(ngModel)]="shippingCollect" role="checkbox" (change)="toggleShippingCollect()">
            <label class="custom-control-label active" for="shippingCollect">Ship collect on my account</label>
          </div>
        </div>
        <div class="table-responsive" *ngIf="shippingCollect && shippingAccountsLoaded && !shippingAccountsLoading">
          <table class="table table-hover">
            <thead class="thead-default">
              <tr>
                <th></th>
                <th>Account Type</th>
                <th>Shipping Account Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let shippingAccount of shippingAccounts; let methodIndex = index">
                <td class="align-middle" [ngClass]="shippingAccountOption === shippingAccount.customerShippingAccountId ? 'selected-blue' : ''">
                  <input
                    name='shippingAccountOption'
                    type='radio'
                    [id]='shippingAccount.customerShippingAccountId'
                    [value]='shippingAccount.customerShippingAccountId'
                    [ngModel]='shippingAccountOption'
                    (ngModelChange)='selectNewShippingAccount(shippingAccount)'
                  >
                  <label [for]="shippingAccount.customerShippingAccountId"></label>
                </td>
                <td class="align-middle"[ngClass]="shippingAccountOption === shippingAccount.customerShippingAccountId ? 'selected-blue' : ''">{{ formatShippingCollectTypes(shippingAccount.type) }}</td>
                <td class="align-middle"[ngClass]="shippingAccountOption === shippingAccount.customerShippingAccountId ? 'selected-blue' : ''">{{ shippingAccount.accountNumber }} </td>
                <td class="link-style" [ngClass]="shippingAccountOption === shippingAccount.customerShippingAccountId ? 'selected-blue' : ''" (click)="assignEditingShippingAccount(shippingAccount)">Edit</td>
              </tr>
            </tbody>
          </table>
          <div>
            <span *ngIf="shippingAccounts.length === 0">
              You have no saved shipping accounts.
            </span>
            <span class="link-style pointer" (click)="addNewShippingAccount()">
              Enter a new shipping account
            </span>
          </div>
          <div class="mt-4 text-muted">
            Please double check your collect account number, any carrier charge-back fees will be billed.
          </div>
        </div>
      </div>
      <div class="d-flex pb-1" *ngIf="showShippingAccountForm">
        <!-- TODO: Fix this - why does this need width 100%? -->
        <app-shipping-account-form class="w-100" [shippingAccount]="editingShippingAccountSubject" (shippingAccountCreated)="shippingAccountCreated($event)" (formVisibility)="toggleShippingAccountForm($event)"></app-shipping-account-form>
      </div>
      <div class="table-responsive" *ngIf="!shippingAddressesLoading">
        <table class="table table-hover" id="shipping-method-table">
          <thead class="thead-default">
            <tr>
              <th></th>
              <th></th>
              <th>Shipping method</th>
              <th>Estimated shipping days</th>
              <th>Estimated cost</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let method of shippingMethods?.rates; let methodIndex = index">
              <td class="align-middle select-col" [ngClass]="outboundShippingOption === method.servicelevel.token ? 'selected-blue' : ''">
                <input
                  name='outboundShippingOption'
                  type='radio'
                  [id]='method.servicelevel.token'
                  [value]='method.servicelevel.token'
                  [(ngModel)]='outboundShippingOption'
                  (ngModelChange)='selectNewShippingMethod(method.servicelevel.token, method)'
                >
                <label [for]="method.object_id"></label>
              </td>
              <td class="align-middle logo-col" [ngClass]="outboundShippingOption === method.servicelevel.token ? 'selected-blue' : ''">
                <img class="min-w-20" [src]="method.provider_image_75"/>
              </td>
              <td class="align-middle method-col" [ngClass]="outboundShippingOption === method.servicelevel.token ? 'selected-blue' : ''">
                <span class="text-gray-dark">{{ method.provider }} {{ method.servicelevel.name }}</span><br>
                <span class="text-muted text-sm">{{ method.duration_terms }}</span>
              </td>
              <!-- <td class="align-middle date-col" [ngClass]="outboundShippingOption === method.servicelevel.token ? 'selected-blue' : ''">{{ this.computeDeliveryTime(method.estimated_days) }}</td> -->
              <td class="align-middle date-col" [ngClass]="outboundShippingOption === method.servicelevel.token ? 'selected-blue' : ''">{{method.estimated_days}} {{ method.estimated_days > 1 ? 'days' : 'day' }} </td>              
              <td class="align-middle cost-col" [ngClass]="outboundShippingOption === method.servicelevel.token ? 'selected-blue' : ''">{{ shippingCollect ? 'Collect' : method.amount | currency:'USD' }}</td>
            </tr>
            <tr *ngIf="!shippingAddressesLoading && shippingMethods?.rates.length === 0">
              <!-- TODO: This block will be responsible for instructing the customers what to do if no shipping options are available -->
              <td colspan="5" class="text-danger text-bold flex-item-center">
                Error retrieving shipping options. Please contact us to complete your order. We apologize for the inconvenience.
                <div>Phone: 800-775-6525</div>
                <div>Email: sales@marcorubber.com</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="shippingAddressesLoading" class="m-0 loading-flex pointer">
        <div class="spinner-border spinner-border-sm gray-dark mr-2 spinner-border-override" role="status"></div>
        Retrieving Shipping Quote...
      </div>
      <div>
        <!-- two versions of the same button; shown at different breakpoints -->
        <button class="btn btn-block btn-primary mb-5 mt-3 mb-5 d-block d-sm-none" (click)="submitShippingDetails()" [disabled]="!selectedAddressId || !outboundShippingOption || shippingAddressesLoading || (shippingCollect && !shippingAccountOption)">
          Continue
        </button>
        <button class="btn btn-primary mb-5 mt-3 d-none d-md-block" (click)="submitShippingDetails()" [disabled]="!selectedAddressId || !outboundShippingOption || shippingAddressesLoading || (shippingCollect && !shippingAccountOption)">
          Continue
        </button>
      </div>
      <!-- Show cart with lead times -->
      <div class="review-wrapper">
        <div class="table-responsive" *ngIf="cart.items.length > 0">
          <div class="d-flex flex-column mt-5">
            <h5>Your Order</h5>
            <div class="table-responsive">
              <table id="ordertable" class="table">
                <thead class="thead-default">
                  <tr>
                    <th>Product Name</th>
                    <th class="text-center">Quantity</th>
                    <th class="text-center">Unit Cost</th>
                    <th class="text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let item of cart.items; let itemIndex = index">
                    <tr app-cart-row-item [item]="item" [disableNavigate]="true" [price]="item.price | currency:'USD'"
                      [pricePerUnit]="item.pricePerUnit | currency:'USD':'symbol':'1.4-4'"
                      [totalPrice]="item.totalPrice | currency:'USD'" [deliveryDateMultiple]="computeItemDeliveryTime(item)"></tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="pt-1" *ngIf="cart.rfqItems.length > 0" [formGroup]="cartForm">
              <h5>Items for Quote</h5>
              <table id="ordertable" class="table">
                <tbody formArrayName="rfqItems">
                  <ng-container *ngFor="let item of rfqItemsControlsArray; let itemIndex = index" >
                    <app-cart-row-rfq-item [item]="item" style="display: contents"></app-cart-row-rfq-item>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #saveUpsAccountTooltipContent>
  <div class="w-100">
    <div class="mb-4">
      Click this button to save your UPS Account ID to your account.
    </div>
    <div>
      By doing so, next time you check out you won't need to re-enter it!
    </div>
  </div>
</ng-template>
